import { library } from "@fortawesome/fontawesome-svg-core";

import { faIzMap, faIzAmenities, faIzProject }
    from "./tools/icons";

import { faPlus, faQuestionCircle, faUser, faSignOutAlt, faSync, faBell, faChevronLeft, faChevronRight, faPaw,
    faExclamationTriangle, faInfoCircle, faCheck }
    from "@fortawesome/free-solid-svg-icons";

import { faUpload, faBan, faHome, faNewspaper }
    from "@fortawesome/pro-light-svg-icons";

import { faEdit, faCheckCircle, faTimesCircle, faCogs, faIdCard }
    from "@fortawesome/pro-regular-svg-icons";

library.add(faIzMap, faIzAmenities, faIzProject, faPlus, faQuestionCircle, faUser, faSignOutAlt, faSync, faBell,
    faUpload, faBan, faEdit, faCheckCircle, faTimesCircle, faCogs, faIdCard, faChevronLeft, faChevronRight, faPaw,
    faExclamationTriangle, faInfoCircle, faCheck, faHome, faNewspaper);